import React, { cloneElement, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export type AppProps = {
  trigger: React.ReactElement;
  title?: string;
  content: (hideModal: () => void) => any;
  footer?: (hideModal: () => void) => any;
  closeModal?: () => void;
  clickOutsideToClose?: boolean;
  className?: string;
  show?: boolean;
  testId?: string | null;
};

const Modal: React.FC<AppProps> = ({
  trigger,
  title = null,
  content,
  footer = null,
  clickOutsideToClose = false,
  className = '',
  testId = null,
  closeModal = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => setIsOpen(true);
  const hideModal = () => {
    setIsOpen(false);
    closeModal();
  };

  const clonedTrigger = cloneElement(trigger, { onClick: showModal });

  return (
    <div data-testid={testId}>
      <div className="cursor-pointer">{clonedTrigger}</div>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={clickOutsideToClose ? hideModal : () => null}
          className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto"
        >
          <div className="h-screen flex justify-center items-center overflow-x-hidden outline-none focus:outline-none">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${className}`}
              >
                {title && (
                  <div className="bg-gray-50 border-b border-solid border-gray-200 rounded-t p-4">
                    <p className="text-lg leading-normal text-gray-900">
                      {title}
                    </p>
                  </div>
                )}
                <div className="px-4 pb-4 overflow-y-auto modal-content-max-height">
                  {content(hideModal)}
                </div>

                {footer && (
                  <div className="w-full bg-gray-50 rounded-bl-lg rounded-br-lg p-4 border-t-1">
                    {footer(hideModal)}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Modal;
