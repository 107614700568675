import React from 'react';

import { yupValidator } from './utils';
import Text from './components/Text';
import TextArea from './components/TextArea';
import PhoneNumber from './components/PhoneNumber';
import Password from './components/Password';
import Select from './components/Select';
import Checkbox from './components/Checkbox';
import Counter from './components/Counter';
import Toggle from './components/Toggle';
import Amount from './components/Amount';
import AmountRange from './components/AmountRange';
import Volume from './components/Volume';
import FileUpload from './components/FileUpload';
import TimePicker from './components/TimePicker';
import TimeRange from './components/TimeRange';
import DatePicker from './components/DatePicker';
import DateRangePicker from './components/DateRangePicker';
import AutoComplete from './components/AutoComplete';
import MultiSelect from './components/MultiSelect';
import { InputType } from './utils';
import clx from 'classnames';

export interface IValidation {
  type: string;
  params: Array<string | number>;
}

export type AppProps = {
  name: string;
  label: string;
  value?: string;
  placeholder?: string;
  variant: InputType;
  validations?: Array<IValidation>;
  testId?: string;
};

const VariantComponentMap: Record<string, any> = {
  [InputType.Phone]: PhoneNumber,
  [InputType.Date]: DatePicker,
  [InputType.DateRange]: DateRangePicker,
  [InputType.TextArea]: TextArea,
  [InputType.Select]: Select,
  [InputType.Check]: Checkbox,
  [InputType.Toggle]: Toggle,
  [InputType.TimePicker]: TimePicker,
  [InputType.TimeRange]: TimeRange,
  [InputType.Password]: Password,
  [InputType.Counter]: Counter,
  [InputType.Amount]: Amount,
  [InputType.AmountRange]: AmountRange,
  [InputType.Autocomplete]: AutoComplete,
  [InputType.MultiSelect]: MultiSelect,
  [InputType.Volume]: Volume,
  [InputType.File]: FileUpload,
  [InputType.Text]: Text,
  [InputType.Email]: Text,
};

const Input = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      label,
      value = '',
      placeholder = '',
      variant = InputType.Text,
      validations = [],
      testId = null,
      ...rest
    },
    ref
  ) => {
    const validator = yupValidator({ type: variant, validations });

    const InputComponent = VariantComponentMap[variant] || Text;
    const inputProps = {
      placeholder,
      name,
      key: name,
      initialValue: value,
      validator,
      label,
      ref,
      variant,
    };

    const inputStyle = clx('w-full flex flex-col items-start justify-start', {
      'space-y-1': !!label,
    });

    return (
      <div className={inputStyle} data-testid={testId}>
        {![InputType.Check, InputType.Toggle, InputType.AmountRange].includes(
          variant
        ) && (
          <p className="text-sm font-medium leading-tight text-gray-700">
            {label}
          </p>
        )}
        <InputComponent {...inputProps} {...rest} />
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
