import React from 'react';
import ReactTooltip from 'react-tooltip';

export type AppProps = {
  children?: React.ReactNode;
  message: string;
  position?: string;
  testId?: string | null;
  disable?: boolean;
};

const Tooltip: React.FC<AppProps> = ({
  children,
  message,
  position,
  testId = null,
  disable = false,
}) => {
  return (
    <div data-tip={message} data-testid={testId}>
      {children}
      <ReactTooltip data-place={position} disable={disable} />
    </div>
  );
};

export default Tooltip;
