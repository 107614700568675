import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Roles } from 'utils/enum';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import Home from 'pages/Home';
import { Variants } from 'components/Terms';

import ProtectedRoute from 'routes/ProtectedRoute';
import Navbar from 'components/Navbar';
import Profile from 'pages/Profile';
import ViewShipperInternalUser from 'pages/Profile/components/InternalUser/ViewShipperInternalUser';
import AdminInternalUser from 'pages/Profile/components/InternalUser/AdminInternalUser';
import ViewAdminInternalUser from 'pages/Profile/components/InternalUser/ViewAdminInternalUser';

const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const ResendTemporarypassword = lazy(() => import('pages/ResendTemporarypassword'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const AccountRequests = lazy(() => import('pages/AccountRequests'));
const AccountRequestDetail = lazy(() => import('pages/AccountRequestDetail'));
const Notification = lazy(() => import('pages/Notification'))
const NotificationPreferences = lazy(() => import('pages/Notification/Preferences'))
const Shipments = lazy(() => import('pages/Shipments'));
const ShipmentDetails = lazy(() => import('pages/ShipmentDetails'));
const LoadDetails = lazy(() => import('pages/LoadDetails'));
const BidDetails = lazy(() => import('pages/BidDetails'));
const LoadBoard = lazy(() => import('pages/LoadBoard'));
const ManageLoad = lazy(() => import('pages/ManageLoad'));
const PostedLoadDetail = lazy(() => import('pages/PostedLoadDetail'));
const NotFound = lazy(() => import('pages/NotFound'));
const Reports = lazy(() => import('pages/Reports'));
const Terms = lazy(() => import('components/Terms'));
const InternalUser = lazy(
  () => import('pages/Profile/components/InternalUser')
);
const InternalUserView = lazy(
  () => import('pages/Profile/components/InternalUser/ViewInternalUser')
);
const ShipperInternalUser = lazy(() => import('pages/Profile/components/InternalUser/ShipperInternalUser'))

const Routes: React.FC = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Navbar />
          <Loader variant={LoaderVariants.Overlay} />
        </div>
      }
    >
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path='/resend-temporary-password'>
          <ResendTemporarypassword />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/terms/service">
          <Terms variant={Variants.Service} />
        </Route>
        <Route path="/terms/privacy">
          <Terms variant={Variants.Privacy} />
        </Route>
        <Route path="/terms/lading">
          <Terms variant={Variants.Lading} />
        </Route>

        <ProtectedRoute path="/change-password" exact>
          <ChangePassword />
        </ProtectedRoute>

        <ProtectedRoute path="/" exact>
          <Home />
        </ProtectedRoute>
        <ProtectedRoute path="/onboarding" exact>
          <Onboarding />
        </ProtectedRoute>

        {/* Account approval route */}
        <ProtectedRoute roles={[Roles.Toggle]} path="/account-requests" exact>
          <AccountRequests />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Toggle]}
          path="/account-requests/:accountType/:accountId"
          exact
        >
          <AccountRequestDetail />
        </ProtectedRoute>

        {/* Notification routes */}
        <ProtectedRoute path='/notifications' exact>
          <Notification />
        </ProtectedRoute>

        <ProtectedRoute path='/notification-preferences' exact>
          <NotificationPreferences />
        </ProtectedRoute>

        {/* Load board routes */}
        <ProtectedRoute path="/load-board" exact>
          <LoadBoard />
        </ProtectedRoute>
        <ProtectedRoute roles={[Roles.Shipper]} path="/create-load" exact>
          <ManageLoad />
        </ProtectedRoute>
        <ProtectedRoute roles={[Roles.Carrier]} path="/profile" exact>
          <Profile />
        </ProtectedRoute>
        <ProtectedRoute roles={[Roles.Carrier]} path="/internal-user" exact>
          <InternalUser />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Carrier]}
          path="/internal-user-view/:internalUserId"
          exact
        >
          <InternalUserView />
        </ProtectedRoute>
        <ProtectedRoute roles={[Roles.Shipper]} path="/internal-user-shipper" exact>
          <ShipperInternalUser />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Carrier]}
          path="/internal-user-shipper-view/:id"
          exact
        >
          <ViewShipperInternalUser />
        </ProtectedRoute>

        <ProtectedRoute roles={[Roles.Shipper]} path="/internal-user-admin" exact>
          <AdminInternalUser />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Carrier]}
          path="/internal-user-admin-view/:id"
          exact
        >
          <ViewAdminInternalUser />
        </ProtectedRoute>

        <ProtectedRoute
          roles={[Roles.Shipper]}
          path="/edit-load/:shipmentId"
          exact
        >
          <ManageLoad />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Shipper]}
          path="/clone-load/:shipmentId"
          exact
        >
          <ManageLoad />
        </ProtectedRoute>
        <ProtectedRoute
          path="/change-request/:shipmentId"
          exact
        >
          <ManageLoad />
        </ProtectedRoute>

        {/* Shipment route */}
        <ProtectedRoute path="/shipments" exact>
          <Shipments />
        </ProtectedRoute>
        <ProtectedRoute
          roles={[Roles.Shipper]}
          path="/posted-load/:shipmentId"
          exact
        >
          <PostedLoadDetail />
        </ProtectedRoute>
        <ProtectedRoute path="/shipments/:shipmentId" exact>
          <ShipmentDetails />
        </ProtectedRoute>

        <ProtectedRoute
          roles={[Roles.Carrier, Roles.Toggle]}
          path="/loads/:shipmentId"
          exact
        >
          <LoadDetails />
        </ProtectedRoute>
        {/* Carrier route */}
        <ProtectedRoute roles={[Roles.Carrier]} path="/bids/:shipmentId" exact>
          <BidDetails />
        </ProtectedRoute>
        <ProtectedRoute roles={[Roles.Toggle]} path="/reports" exact>
          <Reports />
        </ProtectedRoute>
        <Route path="/*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense >
  );
};

export default Routes;
