import React from 'react';
import List, { ListAppProps } from 'components/List';
import Breadcrumb from 'components/Breadcrumb';
import EditInternaUserModal from './EditInternalUser';
import Icon from 'components/Icon';
import {
  GET_ALL_SHIPPERS_BY_ID,
  GET_SHIPPER_BY_ID,
  // GET_ALL_INTERNAL_USERS_BY_ID,
  // GET_ALL_ROLES,
} from 'queries/shipper';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
// import { GET_TOGGLE_EMPLOYEES_OF_A_SHIPPER } from 'queries/shipper';
type AppProps = {
  testId?: string | null;
};
const ViewShipperInternalUser: React.FC<AppProps> = ({ testId = null }) => {
  const pagePath = [
    { label: 'Internal Team', path: '/internal-user-shipper' },
    {
      label: 'View User',
      path: '#',
    },
  ];
  const internalUserId: any = useParams();
  console.log('shipperData1q', internalUserId)
  // const { data: roles, loading: fetching } = useQuery(GET_ALL_ROLES);
  // const { data: internalUserData, loading } = useQuery(
  //   GET_ALL_INTERNAL_USERS_BY_ID,
  //   {
  //     variables: { getInternalUserById: internalUserId },
  //   }
  // );
  const { data: shipperData, loading: fetching, error: fetchError } = useQuery(GET_SHIPPER_BY_ID,
    {
      variables: { id: internalUserId.id },
      fetchPolicy: 'network-only',
    });
  console.log('shipperData1', shipperData, internalUserId)
  const internalUserDetailsItems: any = [
    {
      label: 'First Name',
      value: shipperData?.getShipperById?.primaryContacts[0]?.firstName,
    },
    {
      label: 'Last Name',
      value: shipperData?.getShipperById?.primaryContacts[0]?.lastName,
    },
    {
      label: 'Email address',
      value: shipperData?.getShipperById?.primaryContacts[0]?.email,
    },
    {
      label: 'Phone Number',
      value: shipperData?.getShipperById?.primaryContacts[0]?.phoneNumber,
    },
    {
      label: 'Assigned Role',
      value: "Shipper User"
    },
  ];
  if (fetching) {
    return <Loader variant={LoaderVariants.Overlay} />;
  }
  return (
    <div className="w-screen h-full bg-gray-100">
      <Breadcrumb
        path={pagePath}
        className="border-gray-200 border-b-1 sticky top-14 z-40"
        testId="togglemanager-accountrequestdetails-breadcrumbs"
      />
      <div className="w-full inline-flex items-center justify-between p-5 bg-white">
        <div>
          <div className="flex items-center">
            <p className="text-3xl font-bold leading-9 text-gray-900">
              Internal User
            </p>
          </div>
        </div>
      </div>
      <div className="shadow bg-white rounded-lg m-5">
        <div className="">
          <div className="">
            <List
              items={internalUserDetailsItems}
              title="View User Info"
            //onEdit={() => { }}
            />
            <div className="absolute top-ranger-icon right-12 filter-search-wrapper">
              {/* <div className=" filter-modal-wrapper">
                <EditInternaUserModal
                  trigger={
                    <Icon
                      className="h-3 w-5 text-primary"
                      name="pencil"
                      onClick={() => {}}
                    />
                  }
                  _id={internalUserId}
                  internalUser={internalUserData?.getInternalUserById}
                  roles={roles}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewShipperInternalUser;