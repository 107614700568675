import React from 'react';
import Icon from 'components/Icon';

export type ListAppProps = {
  label?: string;
  labelNode?: React.ReactNode;
  value: string | React.ReactNode;
};

export type AppProps = {
  title?: any;
  items: ListAppProps[];
  onEdit?: any;
  className?: any;
  testId?: string | null;
};

const List: React.FC<AppProps> = ({
  title,
  items,
  onEdit = null,
  className = '',
  testId = null,
}) => {
  return (
    <div
      className={`w-full h-full text-white ${className}`}
      data-testid={testId}
    >
      <div className="bg-white">
        {title && (
          <div className="px-4 pt-5 pb-5 bg-gray-50 flex items-center justify-between">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              {title}
            </h3>
            {onEdit && (
              <div onClick={onEdit}>
                <Icon
                  name="pencil"
                  className="h-5 w-5 text-primary cursor-pointer"
                />
              </div>
            )}
          </div>
        )}
        <div className="border-t border-gray-200">
          <dl>
            {items.map((item, index) => {
              return (
                <div
                  key={`${item.label}-${index}`}
                  className="border-b-1 border-gray-200 px-4 py-5 sm:grid sm:grid-cols-3"
                >
                  {item.label && (
                    <dt className="text-sm leading-5 font-medium text-gray-500">
                      {item.label}
                    </dt>
                  )}
                  {item.labelNode}
                  <dd className="mt-1 text-sm font-normal leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {item.value}
                  </dd>
                </div>
              );
            })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default List;
