export const ShipmentStatusLabel: any = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  DRAFT: 'Draft',
  IN_TRANSIT: 'In Transit',
  NOT_READY: 'Not Ready',
  OPEN: 'Open',
  PENDING_CONFIRMATION: 'Pending confirmation',
  PICKED_UP: 'Picked Up',
  READY_FOR_PICKUP: 'Ready for Pickup',
};

export const ShipmentStatusColor: any = {
  ACTIVE: 'text-green-800',
  CANCELLED: 'text-red-800',
  COMPLETED: 'text-green-800',
  DRAFT: 'text-gray-800',
  IN_TRANSIT: 'text-purple-800',
  NOT_READY: 'text-purple-800',
  OPEN: 'text-yellow-800',
  PENDING_CONFIRMATION: 'text-purple-800',
  PICKED_UP: 'text-purple-800',
  READY_FOR_PICKUP: 'text-yellow-800',
};

export const ShipmentStatusBgColor: any = {
  ACTIVE: 'bg-green-100',
  CANCELLED: 'bg-red-100',
  COMPLETED: 'bg-green-100',
  DRAFT: 'bg-gray-100',
  IN_TRANSIT: 'bg-purple-100',
  NOT_READY: 'bg-purple-100',
  OPEN: 'bg-yellow-100',
  PENDING_CONFIRMATION: 'bg-purple-100',
  PICKED_UP: 'bg-purple-100',
  READY_FOR_PICKUP: 'bg-yellow-100',
};

export const BidStatusLabel: any = {
  BID_EXPIRED: 'Bid Expired',
  BID_PLACED: 'Open',
  CONFIRM_OFFER: 'Confirm Offer',
  NOT_AVAILABLE: 'Not Available',
  OFFER_CONFIRMED: 'Offer Confirmed',
};

export const BidStatusColor: any = {
  BID_EXPIRED: 'text-red-600',
  BID_PLACED: 'text-yellow-800',
  CONFIRM_OFFER: 'text-blue-800',
  NOT_AVAILABLE: 'text-red-800',
  OFFER_CONFIRMED: 'text-green-800',
};

export const BidStatusBgColor: any = {
  BID_EXPIRED: '',
  BID_PLACED: 'bg-yellow-100',
  CONFIRM_OFFER: 'bg-blue-100',
  NOT_AVAILABLE: 'bg-red-100',
  OFFER_CONFIRMED: 'bg-green-100',
};

export const PRIMARY_COLOR = '#4f46e5';
export const ERROR_COLOR = '#ef4444';

export const LOCATION_POLLING_TIME = 10 * 1000;
export const BID_POLLING_TIME = 15 * 1000;

export const DEFAULT_MIN_RATE = 1000;
export const STEP_IN_MAX_RATE = 100;
export const OTHER_HANDLING_UNIT = 'OTHER';

// A function that does nothing
export const emptyFunction = () => {};
