import * as yup from 'yup';

export enum InputType {
  Text = 'text',
  Number = 'number',
  TextArea = 'textarea',
  Amount = 'amount',
  AmountRange = 'amountRange',
  Check = 'check',
  Phone = 'phone',
  Email = 'email',
  Password = 'password',
  Toggle = 'toggle',
  Date = 'date',
  DateRange = 'dateRange',
  Select = 'select',
  Volume = 'volume',
  File = 'file',
  Counter = 'counter',
  Autocomplete = 'autocomplete',
  TimePicker = 'timepicker',
  TimeRange = 'timerange',
  MultiSelect = 'multiselect',
}

interface Config {
  validations: Array<Record<string, any>>;
  type: InputType;
}

const yupMap = {
  text: yup.string,
  number: yup.string,
  textarea: yup.string,
  amount: yup.number,
  amountRange: yup.number,
  check: yup.boolean,
  phone: yup.string,
  email: yup.string,
  password: yup.string,
  date: yup.date,
  select: yup.string,
  file: yup.string,
  counter: yup.number,
  volume: yup.string,
  autocomplete: yup.string,
  timepicker: yup.string,
  multiselect: yup.array,
  dateRange: yup.date,
  timerange: yup.string,
};

export const yupValidator: any = (config: Config) => {
  const { type, validations = [] } = config;
  // @ts-ignore
  if (!yupMap[type]) {
    return null;
  }
  // @ts-ignore
  let yupFn = yupMap[type]();
  validations.forEach((validation) => {
    const { type, params } = validation;
    // @ts-ignore
    if (!yupFn[type]) {
      return null;
    }
    //@ts-ignore
    yupFn = yupFn[type](...params);
  });
  return yupFn;
};
